import React from "react";
import Img from "gatsby-image";
import "./index.css";
import "../style/font-style.css";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import AOS from "aos";
import "aos/dist/aos.css";
import { useStaticQuery, graphql, Link } from "gatsby";
import Head from "../components/head";
import Header from "../components/Header_normal";
import RecentNews from "../components/RecentNews";
import Profile from "../components/Profile";
import Media from "../components/Media";
import Content from "../components/content";
import Footer from "../components/footer";
import Pastevent from "../components/pastevent";
import { Hidden } from "@material-ui/core";
import BoxTypo from "../components/BoxTypo";
import NewsArea from "../components/newsArea";
import { StoreInfo } from "../components/StoreInfo";
import MediaQuery from "react-responsive";

export default function Home() {
  if (typeof document !== `undefined`) {
    AOS.init({
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      delay: 600, // values from 0 to 3000, with step 50ms
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false,
      anchorPlacement: "bottom-bottom",
    });
  }
  const data = useStaticQuery(graphql`
    query {
      topImage: file(relativePath: { eq: "piccante-Top-Img.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dinerImage: file(relativePath: { eq: "diner-picture.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Catervre: file(relativePath: { eq: "catervre.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      HitoyoshiImg: file(relativePath: { eq: "hitoyoshi-img.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vactorLine: file(relativePath: { eq: "vactorLine.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      IlMacaone: file(relativePath: { eq: "IlMacaone.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      caterverleft: file(relativePath: { eq: "caterver-left.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hitoyoshi: file(relativePath: { eq: "hitoyoshi.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pivot: file(relativePath: { eq: "pivot.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ReverceDinerPicture: file(
        relativePath: { eq: "reverce-diner-picture.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allMicrocmsNews {
        edges {
          node {
            newsId
            title
            date
            content
            image {
              url
            }
          }
        }
      }
    }
  `);

  const companyLists = [
    "Company name",
    "President",
    "Vice-presodent",
    "Established day",
    "Capital stock",
  ];
  const companyListsSec = [
    "株式会社ピッカンテ",
    "嘉藤彩乃",
    "永石守利武",
    "2017年6月1日",
    "4,000（千円）",
  ];
  const StoreArray = [
    {
      assetUrl: data.IlMacaone.childImageSharp.fluid,
      storeName: "トリッペリア　イル　マカオーネ",
      title1: "本場フィレンツェのトリッパと",
      title2: "美味しいワインのお店",
      cont1: "白金６丁目の小さなトリッペリア。粋な",
      cont2: "シェフとトリッパが名物です。ワインは",
      cont3: "イタリアワインを中心に、美味しいものを",
      cont4: "世界各地からコレクションしております。",
      bg: "#343441",
      more: "/ilmacaone",
    },
    {
      assetUrl: data.caterverleft.childImageSharp.fluid,
      storeName: "ケータブル",
      title1: "ケータリングとオードブルの",
      title2: "いいとこ取り",
      cont1: "ケータリングは敷居が高い。オードブル",
      cont2: "では物足りない。そんな方のためのサー",
      cont3: "ビスです。",
      cont4: "",
      bg: "#343441",
      more: "/catervre",
    },
    {
      assetUrl: data.hitoyoshi.childImageSharp.fluid,
      storeName: "人吉ぴっかんて",
      title1: "日本初の試み",
      title2: "熊本県人吉市の唐辛子プロジェクト",
      cont1: "長年の試行錯誤の結果ついに商品化に成",
      cont2: "功​。国内では弊社のみが生産するとって",
      cont3: "も貴重な国産イタリアン唐辛子をご用意",
      cont4: "いたしました。",
      bg: "#b8a07c",
      more: "/piccante",
    },
    {
      assetUrl: data.pivot.childImageSharp.fluid,
      storeName: "ピボット",
      title1: "金沢一の繁華街『片町』にある",
      title2: "カジュアルバー",
      cont1: "金沢片町のカジュアルなカラオケ&ゲームバー",
      cont2: "かっこいい女性マスターとのトークも",
      cont3: "お楽しみいただけます。",
      cont4: "",
      bg: "#b8a07c",
      more: "/pivot",
    },
  ];

  return (
    <>
      <Head />
      <Box width="100%" bgcolor="#fff">
        <Header textColor="#343441" BGColor="#F7F6F4" />
        <Box
          className="fv"
          width="100%"
          margin="auto"
          py={13}
          position="relative"
          bgcolor="#f7f6f5"
        >
          <Img fluid={data.topImage.childImageSharp.fluid} />
          <Box m="50px" textAlign="center">
            <BoxTypo fs={["24px", "36px", "48px"]} ls="0" fw="bold">
              “O shi go to .
            </BoxTypo>
            <BoxTypo fs="12px">事業内容</BoxTypo>
          </Box>

          <StoreInfo StoreArray={StoreArray} />

          <Box py={[4, 8, 10]} width="100%">
            <BoxTypo
              fs={["24px", "30px", "48px"]}
              fw="900"
              ls="0"
              textAlign="center"
              mt={["40px", " 60px", "100px"]}
            >
              “Kokoro zashi.
            </BoxTypo>
            <BoxTypo fs={["14px", "14px", "14px"]} textAlign="center">
              社是
            </BoxTypo>
            <BoxTypo
              textAlign="center"
              fs={["16px", "24px", "36px"]}
              lh={["1.8", "1.8", "2"]}
              fw="bold"
              my={["30px", "40px", "60px"]}
              ls="3px"
            >
              食を通じて新たな価値を創出する
              <br />
              食に関わる全ての人が幸せな世界に
            </BoxTypo>
          </Box>
          <Box py={[4, 8, 10]} width="100%">
            <BoxTypo
              fs={["24px", "30px", "48px"]}
              fw="900"
              ls="0"
              textAlign="center"
              mt={["40px", " 60px", "100px"]}
            >
              “Saiyo zyoho.
            </BoxTypo>
            <BoxTypo fs={["14px", "14px", "14px"]} textAlign="center">
              採用情報
            </BoxTypo>
            <Hidden smUp>
              <BoxTypo
                textAlign="center"
                fs={["12px", "16px", "16px"]}
                lh={["2", "1.8", "2"]}
                my={["30px", "40px", "60px"]}
                ls="3px"
              >
                株式会社ピッカンテでは、一緒に働く
                <br />
                仲間を募集しています。
                <br />
                お気軽にお問い合わせください。
              </BoxTypo>
            </Hidden>
            <Hidden xsDown>
              <BoxTypo
                textAlign="center"
                fs={["12px", "16px", "16px"]}
                lh={["1.5", "1.8", "2"]}
                my={["30px", "40px", "60px"]}
                ls="3px"
              >
                株式会社ピッカンテでは、一緒に働く仲間を募集しています。
                <br />
                お気軽にお問い合わせください。
              </BoxTypo>
            </Hidden>
            <Box
              textAlign="center"
              margin="auto"
              // position={["static", "static", "absolute"]}
              style={{ bottom: "0" }}
            >
              <a href="mailto:info@piccante.co.jp">
                <Box
                  textAlign="center"
                  bgcolor="#b8a07c"
                  margin="auto"
                  width={["40%", "30%", "25%", "18%"]}
                  mt={["20px", "20px", "20px"]}
                >
                  <BoxTypo
                    color="#fff"
                    p={["10px 20px", " 10px 30px", "10px 50px"]}
                  >
                    採用のお問合せ
                  </BoxTypo>
                </Box>
              </a>
            </Box>
          </Box>
          <Box py={[4, 8, 10]} width="100%">
            <BoxTypo
              fs={["24px", "30px", "48px"]}
              fw="900"
              ls="0"
              textAlign="center"
              mt={["40px", " 60px", "100px"]}
            >
              “Watashitachi.
            </BoxTypo>
            <BoxTypo fs={["14px", "14px", "14px"]} textAlign="center">
              会社概要
            </BoxTypo>
            <Box
              display={["block", "block", "block", "flex"]}
              width={["100%", "90%", "70%"]}
              m={"auto"}
              py={["40px", "50px", "60px"]}
            >
              <Box
                display="flex"
                justifyContent="center"
                margin="auto"
                width={["100%", "100%", "100%", "70%"]}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-around"
                >
                  {companyLists.map((list, index) => (
                    <BoxTypo key={index} fs={["12px", "16px", "18px"]} lh="2.5">
                      {list}
                    </BoxTypo>
                  ))}
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-around"
                  pl={["20px", "30px", "40px"]}
                >
                  {companyListsSec.map((list, index) => (
                    <BoxTypo key={index} fs={["12px", "16px", "18px"]} lh="2.5">
                      {list}
                    </BoxTypo>
                  ))}
                </Box>
              </Box>
            </Box>
            {/* <MediaQuery query="(max-width: 768px)">
              <Box>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3242.324022408544!2d139.71927811514388!3d35.64438768020293!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b11e38775e5%3A0x3c7e4da6cfb3d98b!2z44CSMTA4LTAwNzIg5p2x5Lqs6YO95riv5Yy655m96YeR77yW5LiB55uu77yS77yT4oiS77yR!5e0!3m2!1sja!2sjp!4v1626344166722!5m2!1sja!2sjp"
                  width="100%"
                  height="300"
                  style={{ border: "0" }}
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </Box>
            </MediaQuery>

            <MediaQuery query="(min-width: 769px)">
              <Box display="flex" justifyContent="center">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3242.324022408544!2d139.71927811514388!3d35.64438768020293!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b11e38775e5%3A0x3c7e4da6cfb3d98b!2z44CSMTA4LTAwNzIg5p2x5Lqs6YO95riv5Yy655m96YeR77yW5LiB55uu77yS77yT4oiS77yR!5e0!3m2!1sja!2sjp!4v1626344166722!5m2!1sja!2sjp"
                  width="500"
                  height="300"
                  style={{ border: "0" }}
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </Box>
            </MediaQuery> */}
          </Box>
        </Box>
        {/* 2ページ目 */}
        {/* <NewsArea /> */}
        <Footer textColor="#FFFFFF" BGColor="#343441" />
      </Box>
    </>
  );
}
